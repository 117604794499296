export default {
  public: [],
  admin: [
    {
      path: 'provider-config',
      name: 'provider-config',
      component: () => import('@/modules/provider/views/providerConfigForm.vue')
    },
    // 供應商列表
    {
      path: 'provider',
      name: 'provider',
      component: () => import('@/modules/provider/views/providerList.vue'),
    },


    // 供應商表單
    {
      path: 'provider/create',
      name: 'provider-create',
      meta: {
        formMode: 'create',
      },
      component: () => import('@/modules/provider/views/providerForm.vue'),
    },
    {
      path: 'provider/update/:target',
      name: 'provider-update',
      meta: {
        formMode: 'update',
      },
      component: () => import('@/modules/provider/views/providerForm.vue'),
    },

  ],
}
